const initialState = {
    isShow: false
};

const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOADER":
            return action.value

        default:
            return false
    }
}

export default LoaderReducer
