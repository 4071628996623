// ** Logo
import logo from '@src/assets/images/logo/logo.svg'
import React from "react";

const SpinnerComponent = () => {
    return (
        <div className='fallback-spinner vh-100' align={'center'}>
            <img className='fallback-logo' src={logo} alt='logo' style={{marginLeft: '-60px'}}/>
            <div className='loading'>
                <div className='effect-1 effects'></div>
                <div className='effect-2 effects'></div>
                <div className='effect-3 effects'></div>
            </div>
        </div>
    )
}

export default SpinnerComponent
