// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import loader from './loader'
import dateRange from './dateRange'

const rootReducer = combineReducers ({
    auth,
    navbar,
    layout,
    loader,
    dateRange
})

export default rootReducer
