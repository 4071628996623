import moment from "moment";

/**
 *
 * @project etenders-admin
 * @author sahan on 9/17/21
 */
const initialState = {
    date: {
        start: Number (moment ().subtract (30, "days")),
        end: Number (moment ().subtract (0, "days"))
    }
};

const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DATE_RANGE":
            return action.value

        default:
            return false
    }
}

export default LoaderReducer
